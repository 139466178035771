export { default as AXIOS } from "axios";

export const log = (...args: any[]) => console.log("AxiosInstance: ", ...args);
export const warn = (...args: any[]) => console.warn("AxiosInstance: ", ...args);
export const error = (...args: any[]) => console.error("AxiosInstance: ", ...args);

export const KEYS = {
  CONFIG: {
    AUTH: "$$AUTH",
    INTERNAL_REQUEST: "$$INTERNAL_REQUEST",
  },
  LOCALSTORAGE_TOKEN: "jwt_token",
  LOCALSTORAGE_TOKEN_MANUAL_EXPIRE: "jwt_manual_expire",
};
